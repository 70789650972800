<!-- 规则弹窗 可以显示自定义div的弹窗 -->
<template>
  <van-dialog v-model=show style="width: 90vw;top:50%;border-radius: 0;background-color: transparent;"
    :show-confirm-button="false">
    <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
      <div style="background: #fff;border-radius: var(--20);">
        <div style="overflow-x: hidden;overflow-y:auto;max-height: 150vw;">
          <slot></slot>
        </div>
      </div>
      <img @click="controlPopup" style="margin-top: 8vw;width: 8.533vw; height: 8.533vw;" src="@/assets/close.png">
    </div>
  </van-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    //控制显示和隐藏弹窗
    controlPopup() {
      let type = "show"
      this[type] = !this[type];
    },
  }
}
</script>

<style scoped>

</style>