<template>
  <div>
    <div>
      <img style="width: var(--32);height: var(--32);" v-if="check" alt="" src="@/common/img/app/check.png">
      <img style="width: var(--32);height: var(--32);" v-else src="@/common/img/app/not_check.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  props: {
    check: {},
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>

</style>
