<template>
  <div class="body">
    <img style="width: 100%;"
         src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/e76af6ca-80a8-4daa-aae7-ff6fcda41003.png">
    <div class="positionAbsolute" style="padding-left: var(--30);padding-right: var(--30);top: 0;">
      <div class="flex_able margin-top30">
        <title-arrow class="topTitle"
                     @click.native="actions.length <= 1 ? showChoiceRedType = false : showChoiceRedType = true"
                     :hideImage="actions.length <= 1"
                     :text="redTopText"
                     text-color="#FFFFFF"
                     image-width="var(--28)"
                     image-height="var(--16)"
                     image-url="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/8d38df9c-9b7c-4e42-af43-11af6ff8074f.png"
                     style="flex: 1;"/>
        <span @click="clickRightTitle" class="font-colorFFFFFF font-size32 lin42">
            红包记录
        </span>
      </div>
      <div class="margin-top30 flex_able flex-directionColumn"
           style="width: var(--690);height: calc(100vh - var(--92) - var(--160));overflow: auto;
           background: #FFFFFF;border-radius: var(--20);padding: var(--30) var(--30) var(--10) var(--30);">
        <div v-if="false"
             class="flex_able item_box" style="margin-top: 0;margin-bottom: var(--30);width: 100%;">
          <span class="item_box_text">{{ redTopNumberDetail }}</span>
          <div class="flex_1"/>
          <img style="width: var(--28);height: var(--16);"
               src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/ea567e1a-0de8-4f06-a47f-8ccb6a09dd69.png">
        </div>
        <div class="flex_able item_box" style="margin-top: var(--0);width: 100%;">
          <span class="item_box_text">红包个数</span>
          <van-field style="margin-left:var(--20);margin-right: var(--20); " v-model="redPackNumber"
                     placeholder="填写红包个数" clearable type="digit"
                     placeholder-class='placeholder-input' maxlength="20" input-align="right"/>
          <span class="item_box_text">个</span>
        </div>
        <div class="flex_able item_box" style="width: 100%;">
          <img v-if="false" class="smallRedPack" src="@/common/img/redPack/pinIcon.png" alt="">
          <span class="item_box_text">{{ redTopText === actions[1].name ? '总金额' : '单个金额' }}</span>
          <van-field style="margin-left:var(--20);margin-right: var(--20); " v-model="redPackMoneyNumber"
                     placeholder="请输入金额" clearable type="number"
                     placeholder-class='placeholder-input' maxlength="20" input-align="right"/>
          <title-arrow
              :hideImage="actionsMoneyType.length <= 1"
              @click.native="showChoiceRedMoneyTypeDialog"
              textColor="#1b1b1b"
              lineHeight="var(--36)"
              textSize="var(--28)"
              imageWidth="var(--28)"
              imageHeight="var(--16)"
              imageMarginLeft="var(--10)"
              imageUrl="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/ea567e1a-0de8-4f06-a47f-8ccb6a09dd69.png"
              :text="redTopNumberDetail"/>
        </div>
        <div v-if="showInsufficientTransferAmount" class="inputError"
             style="position:relative;display: flex;align-items: center;width: 100%;">
          <div style="position: absolute;right: 0;">
            红包额度不足，当前红包剩余额度：{{ amountLimitUnused }}{{ redTopNumberDetail }}
          </div>
        </div>
        <div v-if="showInsufficientAccount" class="inputError"
             style="position:relative;display: flex;align-items: center;width: 100%;">
          <div style="position: absolute;right: 0;">
            账户余额不足
          </div>
        </div>

        <div v-if="false" class="flex_able item_box"
             :class="{redPackGreetings: showInsufficientTransferAmount || showInsufficientAccount}">
          <van-field v-model="redPackGreetings"
                     placeholder="恭喜发财，大吉大利" clearable type="text"
                     placeholder-class='placeholder-input' maxlength="20" input-align="left"/>
        </div>
        <div class="flex_able item_box" style="width: 100%;">
          <span class="item_box_text flex_1">红包口令</span>
          <span class="item_box_text" style="color:#E4514F;">是</span>
        </div>
        <div v-if="false" class="flex_able item_box">
          <span style="flex: 1" class="item_box_text">可用{{ redTopNumberDetail }}</span>
          <span class="item_box_text" style="color: #026AFC;">{{ availableMoney }}</span>
        </div>
        <div v-if="false" class="flex_able" style="margin-top: var(--30);">
          <span class="item_box_text">手续费：</span>
          <span style="color:#026AFC;" class="item_box_text">{{ redPackHandlingFee }}{{ redTopNumberDetail }}</span>
          <div class="flex_1"></div>
          <div>
            <title-arrow @click.native="showRedPackRule"
                         class="showRedPackRule"
                         image-url="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/27531af2-8daa-4d98-bc51-b29808b2d7c9.png"
                         text-color="#026AFC"
                         text-size="var(--28)"
                         image-width="var(--14)"
                         image-height="var(--24)"
                         text="使用规则"/>
          </div>
        </div>
        <!--    红包条件    -->
        <div style="width: 100%;" class="font-size30 font-color1B1B1B line-height42 font-weightBold margin-top30">
          抢红包条件
        </div>
        <div class="margin-top8" style="width: 100%;">
          <div v-for="(item, index) in checkResult" :key="index">
            <div class="flex_able_1 margin-top20"
                 :style="{'alignItems': item.layout ? 'center' : 'top'}">
              <div @click='item.check = !item.check' class="flex_1 flex_able_1">
                <check-box style="width: var(--32);" :check="item.check"/>
                <div class="font-size28 font-color1B1B1B line-height40 margin-left20">
                  {{ item.title }}
                </div>
              </div>
              <div v-if="item.layout" class="font-size28 font-color1B1B1B line-height40 flex_center"
                   style="width: var(--494);height: var(--68);background: #FAFAFA;border-radius: var(--10);">
                {{ item.content }}
              </div>
              <div v-if="!item.layout" class="font-size28 font-color1B1B1B line-height40"
                   style="width: var(--494);height: var(--350);background: #FAFAFA;
                   border-radius: var(--10);padding: var(--16) var(--30)">
                <van-field class="tag_input_address" v-model="item.content" placeholder="请填写地址"
                           type="textarea"/>
              </div>
            </div>
          </div>
        </div>
        <div class="flex_1">

        </div>
        <div style="margin-top: var(--64);width: 100%;align-items:baseline;" class="flex_center">
          <span class="item_box_text twoLine" style="font-size: var(--88)">{{ redPackMoneyNumberStr }}</span>
          <span class="item_box_text" style="font-size: var(--40);margin-left: var(--18);">
            {{ redTopNumberDetail }}
          </span>
          <span v-if="false" class="item_box_text"
                style="font-size: var(--26);margin-left: var(--18);color:#999999 ">(含手续费)</span>
        </div>
        <div class="flex_center" style="flex-direction: column">
          <div @click="sureClick" class="sure flex_center">
            发红包
          </div>
          <div v-if="false" @click="skipFrequencyPage" class="sureLine flex_center">
            获得发红包次数
          </div>
        </div>
        <div style="height: var(--84);"></div>
      </div>

    </div>
    <div style="flex: 1;"></div>
    <div class="bottomText">
      未领取的红包，将于24小时后发起退款
    </div>
    <van-action-sheet v-model="showChoiceRedType" :actions="actions" @select="onSelect" cancel-text="取消"/>
    <van-action-sheet v-model="showChoiceRedMoneyType" :actions="actionsMoneyType" @select="onSelectMoneyType"
                      cancel-text="取消"/>
    <!--  红包规则  -->
    <rule-div-dialog ref="showRuleDivDialog">
      <RuleItem :contentList="haoYouZhuLi"/>
    </rule-div-dialog>
    <div v-if="false" class="positionAbsolute flex_able" style="left: 0;right:0;top: var(--58);">
      <div @click="backPage" class="flex_center" style="width: var(--88);height: var(--88);">
        <img style="width:var(--48);height: var(--48);" src="@/common/img/app/white_back.png"/>
      </div>
      <div class="font-size36 font-colorFFFFFF flex_center line-height50" style="flex: 1;margin-right: var(--88);">
        发红包
      </div>
    </div>

  </div>
</template>

<script>
import {Constants} from "@/utils/constants";
import TitleArrow from "@/components/TitleArrow";
import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import RuleDivDialog from "@/components/RuleDivDialog.vue";
import RuleItem from "@/components/RuleItem.vue";
import CheckBox from "@/pages/app/redPack/components/checkBox.vue";

export default {
  components: {CheckBox, TitleArrow, RuleDivDialog, RuleItem},
  data() {
    return {
      redTopText: "",
      redTopNumberDetail: "",
      redPackNumber: "",//红包个数
      redPackMoneyNumber: "",//红包金额
      redPackMoneyNumberStr: "0",//计算最终的金额
      redPackMoneyNumberNoHandlingFeeStr: "0",//计算最终的金额没手续费的
      redPackGreetings: "让人人都能拥有数字资产",//写死了 祝福语
      redPackHandlingFee: 0,//手续费
      serviceChargeProportion: 0,//手续费比例
      availableMoney: 0,//剩余可用金额
      amountLimitUnused: 0,//剩余可用额度
      redPackCoinConfig: {},//选中币种后的红包配置
      showInsufficientTransferAmount: false,//是否显示转账额度不足
      showInsufficientAccount: false,//是否显示账户余额不足
      showChoiceRedType: false,//显示红包弹出类型弹出
      choiceRedMoneyType: "",//选择的币种
      actions: [
        {name: Constants.puTong},
        {name: Constants.pinShouQi},
        // {name: Constants.kouLing},
      ],
      showChoiceRedMoneyType: false,//显示发红包钱类型
      isCan: false,//是否可发红包
      isCanMsg: "",//是否可发红包原因
      actionsMoneyType: [],
      totalRedPacketUuid: "",
      haoYouZhuLi: [
        {"title": "红包规则："},
        {"content": "1.单个发出的红包限额5~200金钻。"},
        {"content": "2.不同等级的魔力每天最大发红包额度不同，魔力等级越高，每天红包额度越多。"},
        {"content": "3.手续费根据上月能量计算，上月能量值累计≥300能量，免手续费；上月能量值累计>=200能量<300，手续费5%；上月能量值累计≥100能量<200能量，手续费10%；上月能量值累计<100能量，手续费 20%。"},
        {"content": "4.红包次数可以通过每天看广告获得，获得的次数永久有效，用完为止。"},
        {"content": "5.同一红包，每个用户只能领取一次，发出和领取的红包可在我的钱包中查看。"},
        {"content": "6.红包功能是为了提升用户之间的互动娱乐，通过用户之间的互相发红包行为来提升社交能力，不允许使用XWorld红包进行交易变现，用户不遵守红包使用规则，私下交易出现被骗责任自担，XWorld APP不承担任何责任。"},
        {"content": "7.首月福利，截止9月30日，所有用户转赠手续费10%。"},
      ],
      moneyList: [],
      checkResult: [
        // {
        //   check: false,
        //   layout: true,
        //   title: "私人订制",
        //   content: "不限"
        // }, {
        //   check: false,
        //   layout: true,
        //   title: "存储",
        //   content: "≥21000"
        // }, {
        //   check: false,
        //   layout: true,
        //   title: "上月消费",
        //   content: "≥10"
        // },
        {
          check: true,
          layout: false,
          title: "其他",
          content: ""
        }
      ]
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "发红包"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FFFFFF',
        textColor: '#1B1B1B',
        showTitleBar: "false"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "false",
        statusBarBgColor: "#ffffff",
        showStatusBar: "false"
      }));
      window.Android.setWebPageRightTitle(JSON.stringify({content: '红包记录', textColor: '#F33D3D'}));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    //主动调用一次
    this.onSelect(this.actions[1])
    this.queryRedPacketConfigBy()
  },
  mounted() {
    window.clickRightTitle = this.clickRightTitle;
  },
  methods: {
    backPage() {
      window.Android.finish()
    },
    showChoiceRedMoneyTypeDialog() {
      if (this.actionsMoneyType.length <= 1) {
        return
      }
      this.showChoiceRedMoneyType = true
    },
    clickRightTitle() {
      this.skipRedPackRecordPage()
    },
    // 跳转到红包记录
    skipRedPackRecordPage() {
      this.$router.push({
        path: '/redPackRecord',
      });
    },
    //显示红包规则
    showRedPackRule() {
      this.$refs.showRuleDivDialog.controlPopup();
    },
    // 发红包
    handOutRedPacketByAdmin() {
      let that = this;
      let redPackGreetingsTemp = this.redPackGreetings
      if (redPackGreetingsTemp.length <= 0) {
        redPackGreetingsTemp = "恭喜发财，大吉大利"
      }
      let distributeType = ""
      if (this.redTopText === Constants.pinShouQi) {
        distributeType = "2"
      }
      if (this.redTopText === Constants.puTong) {
        distributeType = "1"
      }
      let userMobilesTemp = ""
      this.checkResult.forEach(item => {
        if (item.layout == false) {
          userMobilesTemp = item.content
        }
      })
      let param = {
        amount: this.redPackMoneyNumber,
        number: this.redPackNumber,
        title: redPackGreetingsTemp,
        distributeType: distributeType,
        type: 3, //1.普通红包,不传默认2.直播 3口令红包
        flag: 2,
        // password: value,
        coinType: that.choiceRedMoneyType,
        userMobiles: userMobilesTemp
      };
      Api.handOutRedPacketByAdmin(param).then(res => {
        if (res.code === 100) {
          Toast("红包发送成功")
        } else {
          Toast(res.msg);
        }
      })
    },
    // 查询红包配置
    queryRedPacketConfigBy() {
      let that = this;
      let param = {
        id: 1,
        coinType: Constants.Z_COIN_KE_YONG,//coinType 2011.金币 20151.Fmc
      }
      Api.queryRedPacketConfig(param).then(res => {
        if (res.code === 100) {
          that.moneyList = []
          that.moneyList = res.data.list
          that.actionsMoneyType = []
          that.moneyList.forEach(item => {
            let param = {
              name: item.coinName
            }
            that.actionsMoneyType.push(param)
          })
          if (that.actionsMoneyType.length > 0) {
            that.onSelectMoneyType(that.actionsMoneyType[0])
          }
          console.log(JSON.stringify(that.moneyList))
        } else {
          Toast(res.msg);
        }
      })
    },
    // 选择
    onSelect(item) {
      this.showChoiceRedType = false;
      this.redTopText = item.name
    },
    // 币种类型选择
    onSelectMoneyType(itemTemp) {
      let that = this;
      console.log(itemTemp.name)
      that.showChoiceRedMoneyType = false;
      that.redTopNumberDetail = itemTemp.name
      that.moneyList.forEach(item => {
        if (itemTemp.name == item.coinName) {
          that.redPackCoinConfig = item
          //选择后进行一些数据初始化
          //可用余额
          that.isCan = item.isCan
          that.isCanMsg = item.isCanMsg
          that.availableMoney = item.balance
          that.amountLimitUnused = item.amountLimitUnused
          // that.serviceChargeProportion = item.serviceChargeProportion
          that.choiceRedMoneyType = item.coinType
        }
      })
    },
    //计算要发的金额
    handleRedPackMoneyNumberStr() {
      let that = this;
      let redPackNumber = this.redPackNumber
      if (redPackNumber.length === 0) {
        redPackNumber = "0"
      }
      let redPackMoneyNumber = this.redPackMoneyNumber
      if (redPackMoneyNumber.length === 0) {
        redPackMoneyNumber = "0"
      }
      let redPackHandlingFee = this.redPackHandlingFee
      if (redPackHandlingFee.length === 0) {
        redPackHandlingFee = "0"
      }
      //没有手续费的金额
      let noRedPackHandlingFee = 0
      if (that.redTopText === Constants.pinShouQi) {
        //计算没有收费费的红包钱
        noRedPackHandlingFee = parseInt(redPackMoneyNumber)
      }
      if (this.redTopText === Constants.puTong) {
        //计算没有手续费的红包钱
        noRedPackHandlingFee = parseInt(redPackNumber) * parseInt(redPackMoneyNumber)
      }
      //计算手续费
      that.redPackHandlingFee = (parseFloat(that.serviceChargeProportion) * noRedPackHandlingFee).toFixed(2)
      //计算红包最后金额
      that.redPackMoneyNumberStr = noRedPackHandlingFee + parseFloat(redPackHandlingFee)
      that.redPackMoneyNumberNoHandlingFeeStr = noRedPackHandlingFee
      // if (that.redPackMoneyNumberStr > that.availableMoney) {
      //   that.showInsufficientAccount = true
      //   that.showInsufficientTransferAmount = false
      // } else if (noRedPackHandlingFee > that.amountLimitUnused) {
      //   //额度计算的时候不算手续费
      //   that.showInsufficientAccount = false
      //   that.showInsufficientTransferAmount = true
      // } else {
      //   that.showInsufficientAccount = false
      //   that.showInsufficientTransferAmount = false
      // }
      //口令红包都隐藏错误
      that.showInsufficientAccount = false
      that.showInsufficientTransferAmount = false
    },
    //塞红包
    sureClick() {
      let that = this
      if (that.isCan === false) {
        Toast(that.isCanMsg);
        return;
      }
      //红包个数为0时，提示“请填写红包个数”
      if (that.redPackNumber == 0) {
        Toast("请填写红包个数")
        return;
      }
      if (that.redPackMoneyNumber == 0) {
        Toast("请填写红包金额")
        return;
      }
      //发红包额度不够
      if (that.showInsufficientTransferAmount) {
        return;
      }
      if (this.redPackMoneyNumber === 0) {
        Toast("请输入红包金额")
        return
      }
      if (this.redPackNumber === 0) {
        Toast("请填写红包个数")
        return
      }
      this.handOutRedPacketByAdmin()
    },
  },
  watch: {
    redTopText(val) {
      console.log(val)
    },
    redPackNumber(val) {
      console.log(val)
      this.handleRedPackMoneyNumberStr()
    },
    redPackMoneyNumber() {
      this.handleRedPackMoneyNumberStr()
    },
    redPackHandlingFee() {
      this.handleRedPackMoneyNumberStr()
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #F8F8F8;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.item_box {
  padding-left: var(--30);
  min-height: var(--108);
  background: #FAFAFA;
  border-radius: var(--10);
  margin-top: var(--30);
  padding-right: var(--30);
}

.redPackGreetings {
  margin-top: 0;
}

.smallRedPack {
  width: var(--34);
  height: var(--36);
  margin-right: var(--14);
}

.item_box_text {
  font-size: var(--30);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1B1B1B;
}

/* 设置背景和padding */
::v-deep .van-cell {
  background-color: transparent;
  padding: 0;
  flex: 1;
}

/* 设置 placeholder 颜色 */
::v-deep .van-field__control::placeholder {
  color: #999999;
}

/* 输入框高度 增加热区 */
::v-deep .van-field__control {
  height: var(--70);
}

/* 设置field里面的字体大小 */
::v-deep .van-field__control {
  color: #1b1b1b;
  font-size: var(--30);
  font-weight: 400;
  line-height: var(--40);
}

::v-deep .van-cell::after {
  border-bottom: none;
}

::v-deep .van-action-sheet__item {
  border-bottom: var(--1) solid #eeeeee;
  color: #1b1b1b;
}

::v-deep .van-action-sheet__cancel {
  color: #FE2B54;
}

.tag_input_address ::v-deep .van-field__control {
  height: var(--320);
}

.inputError {
  height: var(--59);
  font-size: var(--24);
  color: #FE2B54;
}

.sure {
  margin-top: var(--90);
  width: var(--626);
  height: var(--88);
  background: #E4514F;
  border-radius: var(--10);
  font-size: var(--30);
  font-weight: 400;
  color: #FFFFFF;
}

.sureLine {
  margin-top: var(--20);
  width: var(--626);
  height: var(--88);
  background: transparent;
  border-radius: var(--10);
  font-size: var(--30);
  font-weight: 400;
  color: #FE444C;
}

.bottomText {
  margin-bottom: var(--78);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--30);
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
