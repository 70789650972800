<!-- 文章条目 -->
<template>
  <div class="article">
    <div v-for="(item, index) in contentList" :key="index">
      <div class="title" v-if="item.title">{{ item.title }}</div>
      <div class="title3" v-if="item.title3">{{ item.title3 }}</div>
      <div class="title4" v-if="item.title4">{{ item.title4 }}</div>
      <img class="image" v-if="item.image" @click="showImage(item.image)" :src=item.image style="width: 100%" />
      <div class="content" v-if="item.content">{{ item.content }}</div>
      <div class="contentRed" v-if="item.contentRed">{{ item.contentRed }}</div>
      <div class="content666666" v-if="item.content666666">{{ item.content666666 }}</div>
      <div class="enter" v-if="item.enter"></div>
      <div class="content" v-if="item.customContent">
        <slot name="customContent"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    showImage(value) {
      this.$emit('handleChange', value) // 触发父组件中handleChange事件并传参Jack
      // 注：此处事件名称与父组件中绑定的事件名称要一致
    }
  }
}
</script>

<style scoped>
.article {
  background: transparent;
  width: 90vw;
  display: flex;
  flex-direction: column;
  padding-left: var(--30);
  padding-right: var(--30);
  margin-top: var(--34);
  margin-bottom: var(--50);
  line-height: var(--40);
}

.title {
  font-size: var(--34);
  color: #1B1B1B;
  font-weight: 600;
}

.title3 {
  font-size: var(--30);
  color: #333333;
  font-weight: 600;
}

.title4 {
  font-size: var(--28);
  color: #333333;
  font-weight: 600;
}

.content {
  margin-top: var(--5);
  margin-bottom: var(--5);
  font-size: var(--28);
  color: #333333;
  font-weight: 400;
}

.contentRed {
  margin-top: var(--5);
  margin-bottom: var(--5);
  font-size: var(--28);
  color: #EC3939;
  font-weight: 400;
}

.content666666 {
  margin-top: var(--5);
  margin-bottom: var(--5);
  font-size: var(--28);
  color: #666;
  font-weight: 400;
}

.enter {
  width: 100vw;
  height: var(--30);
}

.image {
  margin-top: var(--5);
  margin-bottom: var(--5);
}
</style>